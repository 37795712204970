.nav-container{
    background-color: black;
    width: 100vw;
    padding: 15px;
   position: fixed;
    z-index: 100;
    
}
.nav-container nav{
    /* position: fixed; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.logo{
    width: 230px;
    object-fit: contain;
    flex: 1;
   
}
.logo img{
    width: 230px;
    
}
.nav-right{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
a{
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 20px;
}
.nav-right a:hover{
    color: goldenrod;
    border-bottom: 2px solid white;
}
.menu-btn{
    width: 30px;
    height: 30px;
    /* position: fixed; */
    right: 70px;
    top: 40px;
    display: none; 
}
.close-btn{
    width: 20px;
    height: 20px;
    /* position: fixed; */
    right: 70px;
    top: 40px;
    display: none; 
}
/* .nav-right a.active{
    border-bottom: 4px solid white;
    padding-bottom: 5px;
} */

@media screen and (max-width:960px){
    /* .nav-container{
        background-color: black;
        width: 100vw;
    } */
    .menu-btn{
        display: flex;
        cursor: pointer;
        z-index: 1000000;
        position: fixed;
        right: 30px;
    }
    .menu-btn:hover{
        transform: rotate(30deg);
        transition: all 250ms ease-in-out;
    }
    .close-btn{
        display: flex;
        cursor: pointer;
        z-index: 1000000;
        position: fixed;
        right: 30px;
    }
    .close-btn:hover{
        transform: rotate(30deg);
        transition: all 500ms ease-in-out;
    }
    .nav-container nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-color: rgba(0, 0, 0, 0.423); */
        width: 100vw;
        position: fixed;
        background-color: black;
        margin-left: -20px;
        /* height: 100vh; */
    }
   
    .nav-right a{
        margin-top: 10px;
    }
    .nav-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -10px;
        gap: 40px;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 70px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 100000 !important;
      }
    
      .nav-right.active {
        background: #242222;
        left: 0;
        /* top: -1000px; */
        opacity: 1;
        transition: all 1s ease-in-out;
        z-index: 100000 !important;
      }
}

