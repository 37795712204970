.contact-container{
    background-color: rgb(2, 1, 1);
    color: white;
    height: 100vh;
    width: 100vw;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    
    
  
}
.contact-container h2{
    text-align: center;
}
.socials{
    display: flex;
    
    /* margin-left: 70px; */
    margin-bottom: 30px;
    z-index: 1;
    
}
.socials img{
    margin-top: 10px;
    width: 35px;
    height: 35px;
    background: white;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
    
   
}
.socials img:hover{
    animation: shake 500ms linear infinite;
}
@keyframes shake {
    0%{
        transform: translateX(-7px);
    }
    50%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-7px);
    }
}
.email{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.email a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.email-image{
    background-color: white;
    width: 60px;
    height: 60px;
   
    border-radius: 50%;
    padding-top: 5px;
    
}
.email-image:hover{
    background-color: goldenrod;
    box-shadow: 2px 2px 10px 2px gold;
}
.email img{
    width: 50px;
    height: 50px;
    padding: 5px;
    object-fit: contain;
    border-radius: 50%;
    /* margin-bottom: 20px; */
    
}
.email-title{
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
}

.tree{
     position: fixed; 
    bottom: 230px;
    right: 5%;
    width: 200px;
    height: 300px;
    z-index: 0;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    /* opacity: 0.5; */
    
    /* margin-bottom: 1000px; */

}

.tree img{
    width: 100%;
    object-fit: cover;
    transition: all 250ms ease-in-out;
}

.tree img:hover{
    /* -webkit-filter: drop-shadow(8px 8px 10px gray); */
    /* filter: contrast(200%); */

  filter: hue-rotate(150deg);

}

@media screen and(max-width:960px){

}