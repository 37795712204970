
.image-container > img:hover{
    transform: scale(1.3);
    transition: all 1s ease-in-out;
    
}



/* .portfolio-image-container h3{
    color: #ca8536;
    padding: 10px;
}
.portfolio-item-container h6{
    text-align: center;
    color: goldenrod;
}
.links{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.links a{
    font-size: 15px;
    background-color: white;
    color: rgb(21, 85, 15);
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.links a:hover{
    color: white;
    background-color: rgb(21, 85, 15);
} */