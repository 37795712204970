.portfolio-main-container{
    background-color: black;
    padding-top: 100px;
}
.portfolio-main-container h1{
    color: white;
    padding-left: 30px;
    font-size: 25px;
    padding-top: -30px;
    text-decoration: underline wavy goldenrod;
   
}

.portfolio-container{
    background-color: black;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.portfolio-item{
    background-color: #242222;
    width: 320px;
    margin: 20px;
    padding: 10px;
    position: relative;
    height: 120px;
    padding-bottom:30px ;
    transition:ease-in-out 1s;
    /* box-shadow: inset 0 0 0 0 #ca8536; */
    border: none;
    outline: none;
    position: relative;
    z-index:1;
   
 
}
.portfolio-item:hover {
    /* background-color:#757b7e; */
    /* background-image:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,.7) 100%); */
    /* background-repeat:no-repeat; */
    /* background-size: 200% 100%;  */
    /* transition:background-size 1s, background-color 1s; */

    /* box-shadow: inset 300px 0 0 0 #ca8536;
    color: black; */
    color: white;
    cursor: pointer;
  }

  .portfolio-item:before{
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background: linear-gradient(135deg, #b78528b4 50%, #fcc101a4);
  }
  .portfolio-item:hover:before{
    transition: 0.5 all ease-in;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
  }
          
.portfolio-item:active {
    background-color:#2980b9; transition:background-size 0
}
.portfolio-item h3{
    
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
}

.portfolio-item a{
    font-size: 15px;
    background-color: white;
    color: rgb(21, 85, 15);
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.portfolio-item a:hover{
    color: white;
    background-color: rgb(21, 85, 15);
}
.portfolio-item span{
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color:#ca8536 ;
    bottom: -10px;
    right: 47%;
}