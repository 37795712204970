
.contact-home{
    margin-top: -300px;
    margin-bottom: -400px;
}
.home-about-container{
    margin: 150px auto;
}

@media screen and (max-width:960px){

.contact-home{
    margin-top: -170px;
   
}
}