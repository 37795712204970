.featuredContainer{
    width: 80%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.featuredContainer h1{
    padding-bottom: 20px;
}
.featuredInnerContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
}
.featuredImageContainer{
    width: 300px;
    height: 300px;

}
.eachFeatured{
    width: 320px;
    height: 405px;
    background-color: #242222;
    position: relative;
} 
.eachFeatured:before{
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background: linear-gradient(135deg, #b78528b4 50%, #fcc101a4);
  }
  .eachFeatured:hover:before{
    transition: 0.5 all ease-in;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
  }
    

.featured-item{
    margin: 20px;
    padding: 10px;
    position: relative;
    transition:ease-in-out 1s;
    /* box-shadow: inset 0 0 0 0 #ca8536; */
    border: none;
    outline: none;
   
    z-index:1;
   
 
}
.featured-item h3{
    font-size: 20px;
    padding-bottom: 10px;
}
.eachFeatured p{
    font-size: 15px;
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.featuredImageContainer img{
    width: 90%;
    height: 250px;
    object-fit: contain;
}