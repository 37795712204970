.home-container{
    background-color: black;
    padding-top: 100px;
    height: 100%;
    width: 100vw;
    z-index: 1 !important;
}
.web-body{
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    justify-content: space-between;
}
.inner-body{
    display: flex;
}
.inner-body h2{
    color: white;
    margin-top: 25px;
    font-size: 50px;
}
.inner-body img{
    width: 350px;
    margin-left: -20px;
   
}

/*  star start */

.star {
    position: absolute;
    left: 0%;
    top: 0%;
   
    height: 2px;
    background: linear-gradient(-45deg, rgb(238, 240, 244), rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px rgb(230, 232, 237));
    animation:
      tail 1s ease-in-out infinite,
      shooting 4s ease-in-out infinite;
}
    .star::before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: 0;
     
      height: 2px;
      background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(240, 241, 243), rgba(0, 0, 255, 0));
      transform: translateX(50%) rotateZ(45deg);
      border-radius: 100%;
      animation: shining 4s ease-in-out infinite;
    }
  
    .star::after {
      
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: 0;
      height: 2px;
      background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(244, 244, 246), rgba(0, 0, 255, 0));
      transform: translateX(100%) rotateZ(45deg);
      border-radius: 100%;
      animation: shining 4s ease-in-out infinite;
      transform: translateX(50%) rotateZ(-45deg);
    }
    
    /* @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        $delay: random(9999) + 0ms;
        top: calc(50% - #{random(400) - 200px});
        left: calc(50% - #{random(300) + 0px});
        animation-delay: $delay;
        // opacity: random(50) / 100 + 0.5;
        
        &::before,
        &::after {
          animation-delay: $delay;
        }
    }
} */
  
  /* @keyframes tail {
    0% {
      width: 0;
    }
    
    30% {
      width: 100px;
    }
    
    100% {
      width: 0;
    }
  } */
  
  @keyframes shining {
    0% {
      width: 0;
    }
    
    50% {
      width: 30px;
    }
    
    100% {
      width: 0;
    }
  }
  
  @keyframes shooting {
    0% {
      transform: translateX(0);
      transform: translateY(-150px);
    }
    
    100% {
      transform: translateX(800px);
    }
  }
  
  @keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    
    100% {
      transform: rotate(45 + 360deg);
    }
  }
  

  /* start end */
.web-socials{
    display: flex;
    color: white;
    margin-left: 70px;
    width: 300px;
    background-color: #e6e0d85e;
    margin-left: 20px;
    padding: 20px;
}
.web-socials p{
    /* font-family: 'Libre Baskerville', serif; */
    /* font-family: 'Fira Code', monospace; */
    /* font-family: 'Barlow', sans-serif;*/
    /* font-family: 'Comforter', cursive;  */
    font-family: 'Space Mono', monospace;
    font-size: 1.5rem;
    text-align: center;
}
.web-socials img{
    margin-top: 10px;
    width: 35px;
    height: 35px;
    background: white;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
    
   
}
.web-socials img:hover{
    animation: shake 500ms linear infinite;
}
@keyframes shake {
    0%{
        transform: translateX(-7px);
    }
    50%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-7px);
    }
}
.body-right img{
    width: 600px;
    height: 600px;
    position: relative;
    top: -100px;
    right: -120px;
    animation: slideLeft 4s linear infinite;
    z-index: 1;
}
@keyframes slideLeft {
    0%{
        transform: translate(500px,80px);
    }
    50%{
        transform: translate(-300px);
    }
    100%{
        transform: translate(500px,-200px);
    }
}
.body-left{
    margin-left: 70px;
    margin-top: 100px;
}
.body-left h1{
    color: #fca311;
    font-family: 'Courier New', Courier, monospace;
}

@media screen and (max-width: 960px){
    .body-left{
        margin-left: 30px;
        margin-top: 60px;
        /* background-color: rgba(255, 255, 255, 0.331); */
        height: 240px;
        padding: 20px;
        position: relative;
        width: 600px;
    }
   
    .inner-body h2{
        font-size: 30px;
        margin-left: 25px;
    }
    .inner-body img{
        width: 200px;
        object-fit: contain;
        margin-left: -20px;
        margin-top: 10px;
        

    }
    .body-left h1{
        font-size: 25px;
        margin-left: 25px;
        width: 100%;
    }
    .web-socials{
        /* margin-left: 70px; */
        width: 100%;
        position: absolute;
        left: -30px;
        margin-bottom: 20px;
        margin-left: 20px;

        /* padding-left: 30px; */
    }
}