
 .portfolio-column-container{
    background-color: #242222;
    padding-bottom: 70px;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding-top: 100px;
    position: relative;
}
.portfolio-column-container button{
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: white;
    color: #242222;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}
.portfolio-column-container button:hover{
    box-shadow: 1px 1px 5px 3px rgba(14, 6, 6, 0.368);
}
.port-inner-container{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

  
}
.port-top-container{
    flex: 2.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.port-inner-bottom{
    flex: 1;
    margin-top: -100px;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #242222;
    padding: 10px;
    
}

.portfolio-column-container h3{
    color: #ca8536;
    padding: 10px;
    font-size: 22px;
    text-align: center;
}
.portfolio-column-container h6{
    text-align: center;
    color: goldenrod;
    font-size: 18px;

}
.links{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.links a{
    font-size: 15px;
    background-color: white;
    color: rgb(21, 85, 15);
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
}
.links a:hover{
    color: white;
    background-color: rgb(21, 85, 15);
} 


@media screen and (max-width: 960px) {
    .portfolio-column-container{
        height: 100vh;
        background-color: #242222;
        padding-bottom: 10px;
    }
    .port-inner-container{
        
        background-color: #242222;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column-reverse;
      
       
    }
    .port-top-container{
        margin-top: 20px;
        padding-bottom: 70px;
    }
    .port-inner-bottom{
        margin-top: 20px;
        margin: auto;
    }
    .portfolio-column-container button{
        position: fixed;
    }
}

