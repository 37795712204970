.about-container{
    width: 100%;
    /* height: 100; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    font-family: 'Space Mono', monospace;
    font-weight: 800;
    padding-top: 150px;
    min-height: max-content;
    /* margin: 250px auto; */
}
.about-left{
    flex: 3;
  
   

}
.about-left-top{
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 50px;
  font-size: 1.2rem;
}
.about-left-bottom{
    margin:30px 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* grid-template-columns: repeat(2, 1fr); */
    padding: 20px;
}
.skill{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cb6d5164;
    margin: 10px;
    padding: 10px;
    height: 330px;
}
.skill img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    object-fit: contain;
    padding: 15px;
    box-shadow: 4px 4px 10px 2px rgba(255, 255, 255, 0.348);
}
.skill p{
    color: white;
    max-width: 200px;
    text-align: center;
    margin-top: 10px;
    font-style: italic;
    font-weight: 100;
}
.about-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
   
}
.about-right img{
    width: 280px;
    height: 500px;
    object-fit: cover;
 
}
.about-right p{
    color: #cb6d51;
    font-style: italic;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
    text-decoration: underline wavy white;
    /* margin-right: 45px; */
   
    
}
.about-right button{
    color: #cb6d51;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 900;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    /* margin-left: 150px; */
    
}
.about-right button:hover{
  background-color: #cb6d51;
  color: white;
}




@media screen and (max-width: 960px) {
    .about-container{
        display: flex;
        flex-direction: column;
        height: max-content;
        padding-top: 150px;
        padding-bottom: 30px;
        min-height: max-content;
        
        
    }
    .about-left{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* height: 1000px; */
        margin-bottom: 10px;
    }
    .about-left-top p{
        width: 100%;
        text-align: left;
        /* white-space: normal; */
        hyphens: auto;
        word-wrap: break-word;
        /* word-spacing: -5px;  */
        /* letter-spacing: -0.8px;  */
    }
    .about-left-bottom{
        display: flex;
        flex-direction: column;
    }
    .about-right{
        display: flex;
        flex-direction: column;
        align-items: center ;
        justify-content: center ;
      
    }
    .about-right button{
        margin-left: -1px;
    }
    .about-right img{
        height: 300px;
    }

    @media screen and (max-width: 400px) {
        .about-left-top p{
            width: 100%;
            text-align: left;
            /* white-space: normal; */
            hyphens: auto;
            word-wrap: break-word;
            /* word-spacing: -5px;  */
            /* letter-spacing: -0.8px;  */
        }
    }
}